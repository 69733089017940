/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DateRangePicker } from "react-date-range";
import PropTypes from "prop-types";
import themeColors from "../../../assets/stylesheets/_var.scss";
import CrossIcon from "../assets/icons/cross.svg";
import Toggle from "../../Toggle/Toggle";
import CalendarButtonCombination from "./CalendarButtonCombination/CalendarButtonCombination";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "../assets/stylesheets/standardYearCalendar.scss";
import { getInDateFormat } from "../../../utils/dataFilter";
import LocalStorage from "../../../utils/LocalStorgae";
import localLong from "../../../data/calendarTransalation";

export default function StandardYearCalendar({
  showCalendar,
  toggleCalendar,
  switchCalendarToggle,
  switchCalendar,
  selectedRange,
  handleSelect,
  multipleRange,
  multipleHandleSelect,
  onApply
}) {
  const { isCompare, dateRange, compareDateRange } = useSelector(
    (state) => state.generalData
  );

  const rangeArray = Object.values(multipleRange);

  const [isToggled, setIsToggled] = useState(isCompare);
  const [selectedLabel, setSelectedLabel] = useState("");
  const { t } = useTranslation();
  const language = LocalStorage.getItem("selectedLanguage");

  useEffect(() => {
    setIsToggled(isCompare);
  }, [isCompare]);

  const labelButtons = [
    {
      label: t("analyticsPage.calendar.standard.labelButtons.label1"),
      key: "today"
    },
    {
      label: t("analyticsPage.calendar.standard.labelButtons.label2"),
      key: "yesterday"
    },
    {
      label: t("analyticsPage.calendar.standard.labelButtons.label3"),
      key: "thisWeek"
    },
    {
      label: t("analyticsPage.calendar.standard.labelButtons.label4"),
      key: "lastWeek"
    },
    {
      label: t("analyticsPage.calendar.standard.labelButtons.label5"),
      key: "thisMonth"
    },
    {
      label: t("analyticsPage.calendar.standard.labelButtons.label6"),
      key: "lastMonth"
    },
    {
      label: t("analyticsPage.calendar.standard.labelButtons.label7"),
      key: "lastYear"
    },
    {
      label: t("analyticsPage.calendar.standard.labelButtons.label8"),
      key: "thisYearToDate"
    }
  ];

  const handleLabelClick = (label) => {
    setSelectedLabel(label);

    // Handle the logic for setting the selected date based on the label here
    const today = new Date();
    const selectedStartDate = new Date(today);
    const selectedEndDate = new Date(today);
    const firstDayOfWeek = today.getDate() - today.getDay();
    const firstDayOfLastWeek = today.getDate() - today.getDay() - 7;
    const lastDayOfLastWeek = firstDayOfLastWeek + 6;

    switch (label) {
      case t("analyticsPage.calendar.standard.labelButtons.label1"):
        // No need to modify selectedStartDate and selectedEndDate for Today
        break;
      case t("analyticsPage.calendar.standard.labelButtons.label2"):
        selectedStartDate.setDate(today.getDate() - 1);
        selectedEndDate.setDate(today.getDate() - 1);
        break;
      case t("analyticsPage.calendar.standard.labelButtons.label3"):
        selectedStartDate.setDate(firstDayOfWeek);
        selectedEndDate.setDate(firstDayOfWeek + 6);
        break;
      case t("analyticsPage.calendar.standard.labelButtons.label4"):
        selectedStartDate.setDate(firstDayOfLastWeek);
        selectedEndDate.setDate(lastDayOfLastWeek);
        break;
      case t("analyticsPage.calendar.standard.labelButtons.label5"):
        selectedStartDate.setDate(1);
        selectedEndDate.setMonth(today.getMonth() + 1, 0);
        break;
      case t("analyticsPage.calendar.standard.labelButtons.label6"):
        selectedStartDate.setMonth(today.getMonth() - 1, 1);
        selectedEndDate.setDate(0);
        break;
      case t("analyticsPage.calendar.standard.labelButtons.label7"):
        selectedStartDate.setFullYear(today.getFullYear() - 1, 0, 1);
        selectedEndDate.setFullYear(today.getFullYear() - 1, 11, 31);
        break;
      case t("analyticsPage.calendar.standard.labelButtons.label8"):
        selectedStartDate.setFullYear(today.getFullYear(), 0, 1);
        // selectedEndDate remains as today
        break;
      default:
        break;
    }

    handleSelect({
      selection: {
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        key: "selection"
      }
    });
  };

  const handleApplyClick = () => {
    if (isToggled) {
      onApply({
        startDate: rangeArray[0].startDate,
        endDate: rangeArray[0].endDate,
        comparedStartDate: rangeArray[1].startDate,
        comparedEndDate: rangeArray[1].endDate
      });
    } else
      onApply({
        startDate: selectedRange[0].startDate,
        endDate: selectedRange[0].endDate
      });
  };

  const handleCancelClick = () => {
    if (isToggled) {
      // Reset the multipleRange state
      multipleHandleSelect({
        selection1: {
          startDate: getInDateFormat(compareDateRange.startDate),
          endDate: getInDateFormat(compareDateRange.endDate),
          key: "selection1"
        },
        selection2: {
          startDate: getInDateFormat(compareDateRange.compareStartDate),
          endDate: getInDateFormat(compareDateRange.compareEndDate),
          key: "selection2"
        }
      });
    } else {
      // Reset the selectedRange state
      handleSelect({
        selection: {
          startDate: getInDateFormat(dateRange.startDate),
          endDate: getInDateFormat(dateRange.endDate),
          key: "selection"
        }
      });
    }

    // Also reset the selected label and toggled state
    setSelectedLabel("");
  };

  const datePickerStyles = {
    fontFamily: themeColors.fontQuickSand
  };

  return (
    showCalendar && (
      <section className="standard-calendar">
        <div className="close-icon">
          <img src={CrossIcon} alt="cross-icon" onClick={toggleCalendar} />
        </div>
        <div className="flex-between calendar-options">
          <div>
            <h3>{t("analyticsPage.calendar.selectDate")}</h3>
          </div>
          <div className="flex calendar-toggle">
            <p
              onClick={switchCalendarToggle}
              className={!switchCalendar ? "active" : ""}
            >
              {t("analyticsPage.calendar.standardYear")}
            </p>
            <p
              onClick={switchCalendarToggle}
              className={switchCalendar ? "active" : ""}
            >
              {t("analyticsPage.calendar.customTitle")}
            </p>
          </div>
        </div>
        <div className="label-and-standard-range flex">
          <div className="label-list-parent">
            <div className="label-list">
              {labelButtons.map((button) => (
                <button
                  key={button.key}
                  type="button"
                  className={selectedLabel === button.label ? "selected" : ""}
                  onClick={() => handleLabelClick(button.label)}
                >
                  {button.label}
                </button>
              ))}
            </div>
            <div
              style={{
                position: "relative",
                bottom: "1rem"
              }}
              className="compare-toggle"
            >
              <Toggle
                isToggled={isToggled}
                setIsToggled={setIsToggled}
                label={t("analyticsPage.calendar.standard.toggleLabel")}
              />
            </div>
          </div>
          <div className="range-selection">
            <div className={isToggled ? "toggle-on-range-selection" : ""}>
              <p className="compare-text">
                {t("analyticsPage.calendar.standard.compareDetails")}
              </p>
              <DateRangePicker
                ranges={isToggled ? rangeArray : selectedRange}
                onChange={isToggled ? multipleHandleSelect : handleSelect}
                color={themeColors.primaryColor2}
                rangeColors={[
                  themeColors.primaryColor,
                  themeColors.primaryColor2
                ]}
                showSelectionPreview
                direction="horizontal"
                editableDateInputs
                dragSelectionEnabled
                showMonthAndYearPickers
                showDateDisplay
                showPreview
                months={2}
                style={datePickerStyles}
                locale={localLong[language]}
              />
            </div>
            <div
              className="button-combo"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CalendarButtonCombination
                firstButtonText={
                  switchCalendar ? t("common.cancelButton") : "Reset"
                }
                secondButtonText={t("analyticsPage.calendar.apply")}
                onClickFirstButton={handleCancelClick}
                onClickSecondButton={handleApplyClick}
              />
            </div>
          </div>
        </div>
      </section>
    )
  );
}

StandardYearCalendar.propTypes = {
  showCalendar: PropTypes.bool.isRequired,
  toggleCalendar: PropTypes.func.isRequired,
  switchCalendar: PropTypes.bool.isRequired,
  switchCalendarToggle: PropTypes.func.isRequired,
  selectedRange: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
      key: PropTypes.string
    })
  ).isRequired,
  handleSelect: PropTypes.func.isRequired,
  multipleRange: PropTypes.shape({
    selection1: PropTypes.shape({
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
      key: PropTypes.string
    }),
    selection2: PropTypes.shape({
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
      key: PropTypes.string
    })
  }),
  multipleHandleSelect: PropTypes.func,
  onApply: PropTypes.func
};
