/* eslint-disable */
import React, { useState } from "react";
import GreenIcon from "../../assets/icons/status (3).svg";
import themeColors from "../../assets/stylesheets/_var.scss";
import "../stylesheets/tagManager.scss";

function CustomTagComponent({
  customTag,
  customTagInfo,
  salesEventId,
  onCheckboxChange,
  isSelected,
  isConnected
}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const getBackgroundColor = () => {
    if (isSelected) return themeColors.primaryColor;
    if (isHovered) return themeColors.primaryColor4;
    return "rgba(255, 255, 255, 1)";
  };

  return (
    <div
      className="custom-main"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Checkbox and active icon */}
      <div className="flex-center">
        {isConnected === 1 && (
          <div className="active-icon">
            <img
              style={{
                width: "20px"
              }}
              src={GreenIcon}
              alt="Verified"
            />
          </div>
        )}
        <label>
          <input
            type="checkbox"
            onChange={(e) =>
              onCheckboxChange(e.target.checked, salesEventId, customTagInfo)
            }
            className="custom-check-box"
            checked={isSelected}
          />
        </label>
      </div>
      {/* Checkbox and active icon ends */}

      {/* Text div */}
      <div
        className="custom-width"
        style={{
          backgroundColor: getBackgroundColor()
        }}
      >
        <p
          className="custom-tag-text"
          style={{
            color: isSelected && themeColors.background
          }}
        >
          {customTag}
        </p>
      </div>
      {/* Text div ends */}
    </div>
  );
}

export default CustomTagComponent;
