/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AddBarGraph from "../../../components/ChartComponents/GeneralBarGraph";
import StackedChart from "../../../components/ChartComponents/StackedChart";
import WorldMap from "../../../components/ChartComponents/WorldMap";
import LineChart from "../DashBoardLandingPage/LineChart";
import NoDataFound from "../../Placeholders/NoDataFound";
import { findPersonaDescription } from "../../../utils/findPersonaDescription";
import PersonaLabel from "../../../components/PersonaLabel";
import Insights from "../../../components/Insights";
import LimitExceeded from "../../Placeholders/LimitExceeded";

export default function CompareDashboard({
  isCompare,
  selectedFilters,
  insightsTitle,
  dashboard,
  updateSelectedFilters
}) {
  const {
    browsersData,
    devicesData,
    countriesData,
    pageUrlData,
    viewsVisitorsData,
    worldData,
    personaData,
    sourceData,
    mediumData,
    campaignData,
    termData,
    contentData
  } = useSelector((state) => state.compareData);

  const {
    allData: allData2,
    insights: insightsToCompare,
    pageURLGraphData,
    graphSepData,
    limitExceeded
  } = useSelector((state) => state.dataToCompare); // compareData
  const { allData: allData1, insights } = useSelector(
    (state) => state.dashboardData
  );
  const { t } = useTranslation();

  const commonProps = {
    isCompare,
    dashboard,
    filterValues: selectedFilters
  };
  const personaDescription = findPersonaDescription(
    allData1,
    commonProps.filterValues,
    t
  );

  if (limitExceeded) return <LimitExceeded />;

  if (allData1.length > 0 && allData2.length > 0)
    return (
      <>
        <Insights
          insightsTitle={insightsTitle}
          isCompare={isCompare}
          insights={insights}
          insightsToCompare={insightsToCompare}
        />
        <StackedChart
          title={t("analyticsPage.personaTitle")}
          views={personaData.views}
          visitors={personaData.visitors}
          secondViews={personaData.secondViews}
          secondVisitors={personaData.secondVisitors}
          labels={personaData.labels}
          updatedComponentFilters={updateSelectedFilters}
          selectedFilters={selectedFilters}
        />
        <PersonaLabel personaDescription={personaDescription} />
        <div
          className="grid grid-template"
          style={{ height: isCompare ? "30rem" : "22.5rem" }}
        >
          <AddBarGraph
            loading={graphSepData?.isLoading}
            title={t("analyticsPage.devicesTitle")}
            labels={devicesData.labels}
            numBars={
              devicesData?.labels?.length < 6
                ? devicesData?.labels?.length
                : "6"
            }
            data={{
              tooltip: t("analyticsPage.visitors"),
              values: devicesData.dataset1
            }}
            compareData={devicesData.dataset2}
            columnData={[
              {
                name: t("analyticsPage.visitors"),
                diff: devicesData?.visitors,
                first: devicesData?.dataset1,
                second: devicesData?.dataset2,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            labels={browsersData.labels}
            loading={graphSepData?.isLoading}
            title={t("analyticsPage.browsersTitle")}
            visitors={browsersData.visitors}
            numBars={
              browsersData?.labels?.length < 4
                ? browsersData?.labels?.length
                : "4"
            }
            data={{
              tooltip: t("analyticsPage.visitors"),
              values: browsersData.dataset1
            }}
            compareData={browsersData.dataset2}
            columnData={[
              {
                name: t("analyticsPage.visitors"),
                diff: browsersData?.visitors,
                first: browsersData?.dataset1,
                second: browsersData?.dataset2,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            labels={countriesData.labels}
            loading={graphSepData?.isLoading}
            title={t("analyticsPage.countryTitle")}
            numBars={
              countriesData?.labels?.length < 6
                ? countriesData?.labels?.length
                : "6"
            }
            data={{
              tooltip: t("analyticsPage.visitors"),
              values: countriesData.dataset1
            }}
            compareData={countriesData.dataset2}
            columnData={[
              {
                name: t("analyticsPage.visitors"),
                diff: countriesData?.visitors,
                first: countriesData?.dataset1,
                second: countriesData?.dataset2,
                number: true
              }
            ]}
            {...commonProps}
          />
        </div>
        <div className="grid">
          <WorldMap
            data={worldData.dataset1}
            compareData={worldData.dataset2}
            diffData={worldData.visitors}
            labels={worldData.labels}
            title={t("analyticsPage.worldTitle")}
            updateFilters={updateSelectedFilters}
            selectedFilters={selectedFilters}
          />
        </div>
        <LineChart
          views={viewsVisitorsData.views}
          visitors={viewsVisitorsData.visitors}
          secondViews={viewsVisitorsData.secondViews}
          secondVisitors={viewsVisitorsData.secondVisitors}
          labels={viewsVisitorsData.labels}
          title={t("analyticsPage.visitorsPageViewsTitle")}
        />
        <AddBarGraph
          loading={pageURLGraphData?.isLoading}
          labels={pageUrlData.labels}
          links={pageUrlData?.links}
          title={t("analyticsPage.pagesTitle")}
          data={{
            tooltip: t("analyticsPage.visitors"),
            values: pageUrlData.dataset1
          }}
          compareData={pageUrlData.dataset2}
          numBars={
            pageUrlData?.labels?.length < 25 ? pageUrlData?.labels?.length : 25
          }
          columnData={[
            {
              id: 1,
              name: t("analyticsPage.visitors"),
              diff: pageUrlData?.visitors,
              first: pageUrlData?.dataset1,
              second: pageUrlData?.dataset2,
              number: true
            },
            {
              id: 2,
              name: t("analyticsPage.views"),
              diff: pageUrlData?.views,
              first: pageUrlData?.views1,
              second: pageUrlData?.views2,
              number: true
            },
            {
              id: 3,
              name: t("analyticsPage.bounce"),
              diff: pageUrlData?.bounce,
              first: pageUrlData?.bounce1,
              second: pageUrlData?.bounce2,
              percent: true
            },
            {
              id: 4,
              name: t("analyticsPage.preSalesEvent"),
              diff: pageUrlData?.preSales,
              first: pageUrlData?.preSales1,
              second: pageUrlData?.preSales2,
              number: true
            },
            {
              id: 5,
              name: t("analyticsPage.salesEvent"),
              diff: pageUrlData?.sales,
              first: pageUrlData?.sales1,
              second: pageUrlData?.sales2,
              number: true
            },
            {
              id: 6,
              name: t("analyticsPage.revenue"),
              diff: pageUrlData?.revenue,
              first: pageUrlData?.revenue1,
              second: pageUrlData?.revenue2,
              euro: true
            }
          ]}
          {...commonProps}
        />
        <div className="grid grid-template">
          <div className="comparison-bar-graph-height">
            <AddBarGraph
              labels={sourceData?.labels}
              loading={graphSepData?.isLoading}
              title={t("personaDashboard.source")}
              numBars={
                sourceData?.labels?.length < 4
                  ? sourceData?.labels?.length
                  : "4"
              }
              data={{
                tooltip: t("analyticsPage.visitors"),
                values: sourceData.counts1
              }}
              compareData={sourceData.counts2}
              columnData={[
                {
                  name: t("analyticsPage.visitors"),
                  diff: sourceData?.counts,
                  first: sourceData?.counts1,
                  second: sourceData?.counts2,
                  number: true
                }
              ]}
              {...commonProps}
            />
          </div>
          <div className="comparison-bar-graph-height">
            <AddBarGraph
              labels={mediumData?.labels}
              loading={graphSepData?.isLoading}
              title={t("personaDashboard.medium")}
              numBars={
                mediumData?.labels?.length < 4
                  ? mediumData?.labels?.length
                  : "4"
              }
              data={{
                tooltip: t("analyticsPage.visitors"),
                values: mediumData.counts1
              }}
              compareData={mediumData.counts2}
              columnData={[
                {
                  name: t("analyticsPage.visitors"),
                  diff: mediumData?.counts,
                  first: mediumData?.counts1,
                  second: mediumData?.counts2,
                  number: true
                }
              ]}
              {...commonProps}
            />
          </div>
          <div className="comparison-bar-graph-height">
            <AddBarGraph
              labels={campaignData?.labels}
              loading={graphSepData?.isLoading}
              title={t("personaDashboard.campaign")}
              numBars={
                campaignData?.labels?.length < 4
                  ? campaignData?.labels?.length
                  : "4"
              }
              data={{
                tooltip: t("analyticsPage.visitors"),
                values: campaignData.counts1
              }}
              compareData={campaignData.counts2}
              columnData={[
                {
                  name: t("analyticsPage.visitors"),
                  diff: campaignData?.counts,
                  first: campaignData?.counts1,
                  second: campaignData?.counts2,
                  number: true
                }
              ]}
              {...commonProps}
            />
          </div>
        </div>
        <div className="grid half-column-grid">
          <div className="comparison-bar-graph-height">
            <AddBarGraph
              labels={termData?.labels}
              loading={graphSepData?.isLoading}
              title={t("personaDashboard.term")}
              numBars={
                termData?.labels?.length < 4 ? termData?.labels?.length : "4"
              }
              data={{
                tooltip: t("analyticsPage.visitors"),
                values: termData.counts1
              }}
              compareData={termData.counts2}
              columnData={[
                {
                  name: t("analyticsPage.visitors"),
                  diff: termData?.counts,
                  first: termData?.counts1,
                  second: termData?.counts2,
                  number: true
                }
              ]}
              {...commonProps}
            />
          </div>
          <div className="comparison-bar-graph-height">
            <AddBarGraph
              labels={contentData?.labels}
              loading={graphSepData?.isLoading}
              title={t("personaDashboard.content")}
              numBars={
                contentData?.labels?.length < 4
                  ? contentData?.labels?.length
                  : "4"
              }
              data={{
                tooltip: t("analyticsPage.visitors"),
                values: contentData.counts1
              }}
              compareData={contentData.counts2}
              columnData={[
                {
                  name: t("analyticsPage.visitors"),
                  diff: contentData?.counts,
                  first: contentData?.counts1,
                  second: contentData?.counts2,
                  number: true
                }
              ]}
              {...commonProps}
            />
          </div>
        </div>
      </>
    );

  return <NoDataFound />;
}

CompareDashboard.propTypes = {
  isCompare: PropTypes.bool,
  selectedFilters: PropTypes.oneOfType([Object]),
  insightsTitle: PropTypes.oneOfType([Object]),
  dashboard: PropTypes.string,
  updateSelectedFilters: PropTypes.func
};
