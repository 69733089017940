/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { formatNumber } from "../../../../utils/dataFilter";
import { ButtonDark } from "../../../../components/inputElements/buttons/MainButton";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import "../assets/subscription.scss";

export default function PlanTile({
  plan,
  showInList,
  currentPlan,
  currentPlanId,
  expiryDate,
  downGradedPlan,
  handlePlanClick
}) {
  const [isHovered, setIsHovered] = useState(null);

  const handleHover = (id) => {
    setIsHovered(id);
  };

  const { t } = useTranslation();

  console.log("plan====>", plan);

  let planName = plan?.planName == undefined ? "" : plan?.planName;
  console.log("planName====>", planName);

  let [text, number] = planName?.split(/\s+/);
  console.log("text====>", text, "number====>", number);

  let finalPlanName =
    text === "Free"
      ? t("common.free")
      : text === "Standard"
      ? t("common.standard")
      : text === "Premium"
      ? t("common.premium")
      : text === "Enterprise"
      ? t("common.enterprise")
      : text === "Top-Up"
      ? t("common.topup")
      : text === "Level"
      ? t("common.level")
      : "";

  let planNumber = text === "Level" ? number : "";

  let planType = plan?.type == "monthly" ? t("common.month") : t("common.year");

  return (
    <div
      style={isMobile ? { height: "90px" } : {}}
      className={`billing-subs plan ${
        isHovered === plan?.planId && !currentPlan ? "converge " : ""
      }${currentPlan && "current"}`}
      onMouseEnter={() => handleHover(plan?.planId)}
      onMouseLeave={() => handleHover(null)}
      onClick={() => {
        if (showInList && plan?.type !== "enterprise")
          handlePlanClick(showInList, plan);
      }}
    >
      {!currentPlan && (
        <>
          <div className="rectangle diagonal-1" />
          <div className="rectangle diagonal-2" />
        </>
      )}

      <div
        className="flex-between"
        style={{
          position: "absolute",
          zIndex: 8
        }}
        key={plan?.planId}
      >
        <div className="left flex-column">
          <div>
            {finalPlanName + " " + planNumber + " "}
            {/* {plan?.planName} */}
            {currentPlan && (
              <span>{t("subscription.billing.currentPlan")}</span>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div className="datapoints">
              {plan?.type === "enterprise" && (
                <div>
                  {t("subscription.billing.moreDetails")}
                  {"  "}
                  <a href="mailto:mail@limendo.com">
                    {t("subscription.billing.contactUs")}
                  </a>
                </div>
              )}

              {plan?.type === "topup" &&
                plan?.type !== "enterprise" &&
                `${t("subscription.billing.youCanGet")} ${formatNumber(
                  plan.views
                )} ${t("subscription.billing.viewsAdded")}`}
              {plan?.type !== "topup" &&
                plan?.type !== "enterprise" &&
                (plan?.views > 0
                  ? `${t("subscription.billing.youCanGet")} ${formatNumber(
                      plan.views
                    )} ${t("subscription.billing.viewsPerMonth")}`
                  : t("subscription.billing.freeTrialMessage"))}
            </div>

            {/* Current plan expiry date */}
            <div className="expirydate">
              {currentPlan && (
                <text
                  style={{
                    fontFamily: themeColors.fontQuickSand,
                    fontWeight: "500",
                    fontSize: "16px"
                    // lineHeight: "20.7px",
                    // letterSpacing: "4.2%"
                    // marginTop: "20px"
                  }}
                >
                  {expiryDate ? (
                    <text>
                      {t("common.expirydate")} : {expiryDate}
                    </text>
                  ) : null}
                </text>
              )}
            </div>
            {/* Current plan expiry date */}
          </div>

          {/* DownGradedPlan message */}
          {currentPlan && downGradedPlan && (
            <div
              style={{
                fontSize: "13px"
              }}
            >
              {downGradedPlan <= 4 && downGradedPlan >= 1 && (
                <text
                  style={{
                    position: "relative",
                    top: "20px"
                  }}
                >
                  *{t("common.levelChose")}-{downGradedPlan} {t("common.for")}{" "}
                  {downGradedPlan === 1
                    ? "Free"
                    : `€${
                        downGradedPlan === 2
                          ? "25"
                          : downGradedPlan === 3
                          ? "45"
                          : "85"
                      }`}
                  . {t("common.effectFrom")} {expiryDate}
                </text>
              )}
            </div>
          )}

          {/* DownGradedPlan message ends */}
        </div>

        <div>
          <div>
            {plan?.type !== "enterprise" ? (
              <div className="flex-column-end right">
                {plan?.amount == undefined ? null : (
                  <div
                    style={{
                      whiteSpace: "nowrap"
                    }}
                    className="amount"
                  >
                    {plan?.type === "topup"
                      ? `${plan?.amount} €`
                      : `${plan?.amount} € / ${planType}`}
                  </div>
                )}

                {showInList && (
                  <ButtonDark
                    buttonText={
                      plan?.type === "topup"
                        ? t("subscription.billing.buyNow")
                        : t("subscription.changePlan")
                    }
                    className="change-plan"
                  />
                )}
              </div>
            ) : (
              <div className="amount">
                {t("subscription.billing.enterprise")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

PlanTile.propTypes = {
  plan: PropTypes.oneOfType([PropTypes.object]),
  showInList: PropTypes.bool,
  currentPlan: PropTypes.bool,
  expiryDate: PropTypes.number,
  downGradedPlan: PropTypes.number,
  handlePlanClick: PropTypes.func
};
