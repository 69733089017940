import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Moment from "moment";
import FilterPill from "../../components/FilterPill/components/FilterPill";
import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import DateCalendar from "../../components/DateRange";
import "../stylesheets/dashboard.scss";
import GeneralDashboard from "../../features/ISIChatDashboard/GlobalDashboard/GeneralDashboard";
import CompareISIChatDashboard from "../../features/ISIChatDashboard/GlobalDashboard/CompareISIChatDashboard";
import {
  updateCategoriesData,
  updateCompareCategoriesData,
  updateCompareFilteredData,
  updateCompareInsights,
  updateCompareMultiBarChartData,
  updateComparePersonaData,
  updateCompareQuestions,
  updateFilteredData,
  updateInsights,
  updateMultiBarChartData,
  updatePersonaData,
  updateQuestions
} from "../../store/slices/isiChatDataSlice";
import {
  getInsights,
  getRecordsByDate,
  modifyData,
  getAllQuestionsByDate,
  getRecordsByMonthly,
  getRecordsByWeekly,
  getRecordsByYearly,
  getPersonaData,
  getCategoriesData,
  compareMergeInsights,
  filterAccordingToSelected
} from "../../utils/isiChatUtil";
import {
  getIsiChatData,
  getAllQuestionsForIsiChat,
  getCompareIsiChatData,
  getCompareAllQuestionsForIsiChat
} from "../../store/apiSlice";
import LocalStorage from "../../utils/LocalStorgae";
import Loading from "../../features/Placeholders/Loading";
import {
  setCompareCategoriesData,
  setCompareMultiBarChartData,
  setComparePerosnaData
} from "../../store/slices/compareISIChatSlice";
import { updateSelectedISIFilters } from "../../store/slices/generalSlice";

export default function IsiChat() {
  const dashboard = "isichat";

  const {
    websiteID,
    selectedISIFilters,
    isCompare,
    dateRange,
    compareDateRange,
    createdBy
  } = useSelector((state) => state?.generalData);
  const language = LocalStorage.getItem("selectedLanguage");

  const {
    allData: allData1,
    isLoading,
    multiBarChartData: multiBarChartData1,
    filteredData: filteredData1,
    questionsData: questionsData1,
    selectedFilterType,
    pageCountOfQuestions: pageCountOfQuestions1,
    personaData: personaData1,
    categoriesData: categoriesData1,
    selectedRange
  } = useSelector((state) => state?.isiChatData);

  const {
    allData: allData2,
    filteredData: filteredData2,
    multiBarChartData: multiBarChartData2,
    questionsData: questionsData2,
    pageCountOfQuestions: pageCountOfQuestions2,
    personaData: personaData2,
    categoriesData: categoriesData2
  } = useSelector((state) => state?.isiChatCompareData);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [pageNumber, setPageNumber] = useState(1);

  const [comparePageNumber, setComparePageNumber] = useState(1);

  const insightsTitle = {
    visitors: t("analyticsPage.visitors"),
    sessions: t("dashboardMenu.sessions"),
    questions: t("isiChatDashboard.questions"),
    tokens: t("isiChatDashboard.tokens"),
    average_time: t("isiChatDashboard.total_spent_time")
  };

  const separateData1 = (data) => {
    dispatch(updateInsights(getInsights(data)));
    if (!selectedISIFilters?.[t("common.date")]) {
      if (selectedFilterType === "Weekly") {
        dispatch(updateMultiBarChartData(getRecordsByWeekly(data)));
      } else if (selectedFilterType === "Monthly") {
        dispatch(updateMultiBarChartData(getRecordsByMonthly(data)));
      } else if (selectedFilterType === "Yearly") {
        dispatch(updateMultiBarChartData(getRecordsByYearly(data)));
      } else {
        dispatch(
          updateMultiBarChartData(
            getRecordsByDate(
              data,
              Moment(
                isCompare ? compareDateRange.startDate : dateRange.startDate,
                "DD-MM-YYYY"
              ).format("YYYY-MM-DD"),
              Moment(
                isCompare ? compareDateRange.endDate : dateRange.endDate,
                "DD-MM-YYYY"
              ).format("YYYY-MM-DD")
            )
          )
        );
      }
    }
    if (!selectedISIFilters?.[t("dashboardMenu.personas")]) {
      dispatch(updatePersonaData(getPersonaData(data)));
    }
    if (!selectedISIFilters?.[t("isiChatDashboard.categories")]) {
      dispatch(updateCategoriesData(getCategoriesData(data)));
    }
    if (
      selectedISIFilters?.[t("dashboardMenu.personas")] &&
      selectedISIFilters?.[t("isiChatDashboard.categories")]
    ) {
      dispatch(updatePersonaData(getPersonaData(data)));
    }
  };
  const separateData2 = (data) => {
    dispatch(updateCompareInsights(getInsights(data)));
    if (!selectedISIFilters?.[t("common.date")]) {
      if (selectedFilterType === "Weekly") {
        dispatch(updateCompareMultiBarChartData(getRecordsByWeekly(data)));
      } else if (selectedFilterType === "Monthly") {
        dispatch(updateCompareMultiBarChartData(getRecordsByMonthly(data)));
      } else if (selectedFilterType === "Yearly") {
        dispatch(updateCompareMultiBarChartData(getRecordsByYearly(data)));
      } else {
        dispatch(
          updateCompareMultiBarChartData(
            getRecordsByDate(
              data,
              Moment(compareDateRange.compareStartDate, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              ),
              Moment(compareDateRange.compareEndDate, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )
            )
          )
        );
      }
    }
    if (!selectedISIFilters?.[t("dashboardMenu.personas")]) {
      dispatch(updateComparePersonaData(getPersonaData(data)));
    }
    if (!selectedISIFilters?.[t("isiChatDashboard.categories")]) {
      dispatch(updateCompareCategoriesData(getCategoriesData(data)));
    }
  };

  useEffect(() => {
    if (isCompare) {
      if (
        compareDateRange.startDate &&
        compareDateRange.endDate &&
        compareDateRange.compareStartDate &&
        compareDateRange.compareEndDate
      ) {
        dispatch(
          getCompareIsiChatData({
            website_id: websiteID,
            dateRange: [
              {
                startDate: compareDateRange.startDate,
                endDate: compareDateRange.endDate
              },
              {
                startDate: compareDateRange.compareStartDate,
                endDate: compareDateRange.compareEndDate
              }
            ],
            customer_id: createdBy
          })
        );
      }
    } else {
      dispatch(
        getIsiChatData({
          website_id: websiteID,
          dateRange: [
            { startDate: dateRange.startDate, endDate: dateRange.endDate }
          ],
          customer_id: createdBy
        })
      );
    }
  }, [dateRange, compareDateRange, isCompare, language, websiteID]);

  useEffect(() => {
    if (Object.keys(selectedISIFilters).length > 0) {
      separateData1(filteredData1);
      if (isCompare) separateData2(filteredData2);
    } else {
      separateData1(modifyData(allData1));
      if (isCompare) separateData2(modifyData(allData2));
    }
  }, [allData1, filteredData1, allData2, filteredData2, language]);

  const filterData = (data) => {
    if (
      selectedISIFilters[t("dashboardMenu.personas")] &&
      selectedISIFilters[t("isiChatDashboard.categories")]
    ) {
      return data.filter(
        (ele) =>
          selectedISIFilters[t("dashboardMenu.personas")].includes(
            ele.personaLabel
          ) &&
          selectedISIFilters[t("isiChatDashboard.categories")].includes(
            ele.categories
          )
      );
    }

    if (selectedISIFilters[t("isiChatDashboard.categories")]) {
      return data.filter((ele) =>
        selectedISIFilters[t("isiChatDashboard.categories")].includes(
          ele.categories
        )
      );
    }

    if (selectedISIFilters[t("dashboardMenu.personas")]) {
      return data.filter((ele) =>
        selectedISIFilters[t("dashboardMenu.personas")].includes(
          ele.personaLabel
        )
      );
    }

    if (selectedFilterType === "Weekly") {
      return data?.filter((ele) =>
        selectedISIFilters?.[t("common.date")]?.includes(ele.weekName)
      );
    }

    if (selectedFilterType === "Monthly") {
      return data?.filter((ele) =>
        selectedISIFilters?.[t("common.date")]?.includes(ele.monthName)
      );
    }

    if (selectedFilterType === "Yearly") {
      return data?.filter((ele) =>
        selectedISIFilters?.[t("common.date")]?.includes(ele.year)
      );
    }

    if (selectedFilterType === "Daily") {
      return data?.filter((ele) =>
        selectedISIFilters?.[t("common.date")]?.includes(ele.dateName)
      );
    }
    return [];
  };

  const filterValuesForQuestion = (filterType, selectedValues) => {
    switch (filterType) {
      case "Daily":
        return selectedValues.map((value) => value);
      case "Weekly":
        return selectedValues.map((value) => {
          return parseInt(value.split(" ").pop(), 10);
        });
      case "Monthly":
        return selectedValues.map((item) => item.split(" ")[0]);
      case "Yearly":
        return selectedValues.map(parseInt);
      default:
        return [];
    }
  };

  const createDateRangeObject = (startDate, endDate, page) => {
    return {
      startDate,
      endDate,
      pageNumber: page,
      filterType: selectedFilterType,
      filterValues:
        Object.keys(selectedISIFilters).length &&
        !selectedISIFilters?.[t("dashboardMenu.personas")] &&
        !selectedISIFilters?.[t("isiChatDashboard.categories")]
          ? filterValuesForQuestion(
              selectedFilterType,
              selectedISIFilters?.[t("common.date")]
            )
          : [],
      personaLabel:
        Object.keys(selectedISIFilters).length &&
        selectedISIFilters?.[t("dashboardMenu.personas")]
          ? selectedISIFilters?.[t("dashboardMenu.personas")]
          : [],
      categories:
        Object.keys(selectedISIFilters).length &&
        selectedISIFilters?.[t("isiChatDashboard.categories")]
          ? selectedISIFilters?.[t("isiChatDashboard.categories")]
          : []
    };
  };

  const getPayload = (page1 = 1, page2 = 1) => {
    let dateRangeArray;
    if (isCompare) {
      dateRangeArray = [
        createDateRangeObject(
          compareDateRange.startDate,
          compareDateRange.endDate,
          page1
        ),
        createDateRangeObject(
          compareDateRange.compareStartDate,
          compareDateRange.compareEndDate,
          page2
        )
      ];
    } else {
      dateRangeArray = [
        createDateRangeObject(dateRange.startDate, dateRange.endDate, page1)
      ];
    }

    return {
      website_id: websiteID,
      dateRange: dateRangeArray
    };
  };

  useEffect(() => {
    dispatch(updateFilteredData(filterData(modifyData(allData1))));
    if (isCompare)
      dispatch(updateCompareFilteredData(filterData(modifyData(allData2))));
    if (Object.keys(selectedISIFilters).length) {
      if (isCompare) {
        dispatch(getCompareAllQuestionsForIsiChat(getPayload()));
      } else {
        dispatch(getAllQuestionsForIsiChat(getPayload()));
      }
    } else if (isCompare) {
      dispatch(getCompareAllQuestionsForIsiChat(getPayload()));
    } else {
      console.log("question called");
      dispatch(getAllQuestionsForIsiChat(getPayload()));
    }
  }, [selectedISIFilters, selectedFilterType, isCompare, dateRange, language]);

  useEffect(() => {
    if (questionsData1?.length) {
      dispatch(updateQuestions(getAllQuestionsByDate(questionsData1)));
    }
    if (isCompare && questionsData2?.length) {
      dispatch(updateCompareQuestions(getAllQuestionsByDate(questionsData2)));
    }
  }, [questionsData1, questionsData2, isCompare]);

  const handlePagination = (e, page) => {
    setPageNumber(page);
    const pageNum = page > 1 ? page : 1;
    // if (isCompare) {
    //   dispatch(getCompareAllQuestionsForIsiChat(getPayload(pageNum)));
    // } else {
    dispatch(getAllQuestionsForIsiChat(getPayload(pageNum)));
    // }
  };

  const handlePagination2 = (e, page) => {
    setComparePageNumber(page);
    const pageNum = page > 1 ? page : 1;
    dispatch(getCompareAllQuestionsForIsiChat(getPayload(pageNumber, pageNum)));
  };

  useEffect(() => {
    if (isCompare && comparePageNumber > 1) {
      dispatch(getCompareAllQuestionsForIsiChat(getPayload()));
      setComparePageNumber(1);
    } else {
      if (pageNumber > 1) {
        dispatch(getAllQuestionsForIsiChat(getPayload()));
      }
      setPageNumber(1);
    }
  }, [selectedFilterType]);

  useEffect(() => {
    setPageNumber(1);
    setComparePageNumber(1);
  }, [pageCountOfQuestions1, pageCountOfQuestions2]);

  const updateCompareData = () => {
    dispatch(
      setCompareMultiBarChartData(
        compareMergeInsights(multiBarChartData1, multiBarChartData2)
      )
    );
    dispatch(
      setComparePerosnaData(compareMergeInsights(personaData1, personaData2))
    );
    dispatch(
      setCompareCategoriesData(
        compareMergeInsights(categoriesData1, categoriesData2)
      )
    );
  };

  useEffect(() => {
    if (
      isCompare &&
      multiBarChartData2.labels?.length > 0 &&
      personaData2.labels?.length > 0 &&
      categoriesData2.labels?.length > 0
    ) {
      updateCompareData();
    }
  }, [isCompare, multiBarChartData2]);

  useEffect(() => {
    if (selectedISIFilters?.[t("isiChatDashboard.categories")]?.length) {
      if (selectedRange === t("common.range_b")) {
        dispatch(
          updateCompareCategoriesData(
            filterAccordingToSelected(
              categoriesData2,
              selectedISIFilters?.[t("isiChatDashboard.categories")]
            )
          )
        );
      }
      if (selectedRange === t("common.range_a")) {
        dispatch(
          updateCategoriesData(
            filterAccordingToSelected(
              categoriesData1,
              selectedISIFilters?.[t("isiChatDashboard.categories")]
            )
          )
        );
      }
    }
  }, [selectedRange, selectedISIFilters?.[t("isiChatDashboard.categories")]]);

  return (
    <div>
      <AuthorizedHeader />
      <div className="dashboard-body">
        <div className="container">
          {/* <div className="flex-between">
          <div className="heading3">ISI chat</div>
        </div> */}
          <div className="flex-between filter-header">
            <FilterPill
              filterValues={selectedISIFilters}
              dashboard={dashboard}
            />
            <DateCalendar />
          </div>
          {isLoading && <Loading />}

          {!isLoading &&
            (isCompare ? (
              <CompareISIChatDashboard
                insightsTitle={insightsTitle}
                isCompare={isCompare}
                selectedFilters={selectedISIFilters}
                dashboard={dashboard}
                handlePagination={handlePagination}
                pageNumber={pageNumber}
                comparePageNumber={comparePageNumber}
                handlePagination2={handlePagination2}
                updateSelectedFilter={updateSelectedISIFilters}
                websiteID={websiteID}
              />
            ) : (
              <GeneralDashboard
                insightsTitle={insightsTitle}
                dashboard={dashboard}
                isCompare={isCompare}
                selectedISIFilters={selectedISIFilters}
                handlePagination={handlePagination}
                pageNumber={pageNumber}
                updateSelectedFilter={updateSelectedISIFilters}
                websiteID={websiteID}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
