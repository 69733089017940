/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import CustomAccordian from "../../../../components/Accordion/CustomAccordian";
import sort from "../../../../assets/icons/sort.svg";
import DataTable from "../../../../components/Table/DataTable";
import download from "../../../../assets/icons/download.svg";
import "../assets/stylesheets/billing.scss";
import { getBillingHistory } from "../api/billing";

function CustomButton() {
  return (
    <IconButton>
      <img src={sort} alt="sort" />
    </IconButton>
  );
}

const handleDownload = (pdfUrl) => {
  const link = document.createElement("a");
  link.href = pdfUrl;
  link.download = "BillingPlan.pdf";
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function BillingHistory() {
  const { t } = useTranslation();
  const { websiteID } = useSelector((state) => state.generalData);
  const { createdBy: customerId } = useSelector((state) => state.generalData);
  const [billingData, setBillingData] = useState([]);
  const [filteredBillingData, setFilteredBillingData] = useState([]);
  const [searchFilter, setSearchFilter] = useState("website_name");

  const searchFilters = [
    {
      name: t("billing.headerName.websites"),
      value: "website_name"
    },
    {
      name: t("billing.headerName.type"),
      value: "plan_for"
    },
    {
      name: t("billing.headerName.plans"),
      value: "plan_name"
    }
  ];

  const columns = [
    {
      field: "website_name",
      headerName: t("billing.headerName.websites"),
      flex: 2
    },
    { field: "plan_for", headerName: t("billing.headerName.type"), flex: 2 },
    { field: "plan_name", headerName: t("billing.headerName.plans"), flex: 2 },
    { field: "amount", headerName: t("billing.headerName.price"), flex: 1 },
    {
      field: "created_date",
      headerName: t("billing.headerName.date"),
      flex: 2
    },
    {
      field: "invoice_pdf",
      headerName: t("billing.headerName.pdf"),
      flex: 2,
      renderCell: (params) => (
        <div
          className="flex-center"
          onClick={() => {
            handleDownload(params.value);
          }}
        >
          <span className="pdf-column-red">PDF</span>
          <img
            src={download}
            alt="PDF Icon"
            className="pdf-icon"
            height="24"
            width="24"
          />
        </div>
      )
    }
  ];

  const handleRowClick = () => {};

  const handleRowSelect = () => {};

  const getBillingSubscription = async () => {
    try {
      const res = await getBillingHistory({ customer_id: customerId });
      setBillingData(res.data.result);
    } catch (errors) {
      // console.log(errors);
    }
  };

  const handleSearch = (searchTerm) => {
    const filteredResults = [];
    if (searchTerm?.length >= 1) {
      billingData?.forEach((bill) => {
        const value = bill[searchFilter];
        const lowerCaseValue = value?.toLowerCase?.();
        const lowerCaseSearchTerm = searchTerm?.toLowerCase();
        const startsWithCondition =
          lowerCaseValue.startsWith(lowerCaseSearchTerm);
        const includesCondition = lowerCaseValue.includes(lowerCaseSearchTerm);
        const exactMatchCondition = lowerCaseValue === lowerCaseSearchTerm;
        if (startsWithCondition) {
          filteredResults.unshift(bill); // Add exact matches to the beginning
        } else if (exactMatchCondition) {
          filteredResults.push(bill); // Add starts with matches after exact matches
        } else if (includesCondition) {
          filteredResults.push(bill); // Add includes matches last
        }
      });
    } else {
      filteredResults.push(...billingData);
    }
    setFilteredBillingData(filteredResults);
  };

  useEffect(() => {
    getBillingSubscription();
  }, [websiteID]);

  return (
    <div>
      <DataTable
        columns={columns}
        rows={
          filteredBillingData?.length > 0 ? filteredBillingData : billingData
        }
        CustomButton={CustomButton}
        onRowClick={handleRowClick}
        onRowsSelect={handleRowSelect}
        search
        searchFilters={searchFilters}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        handleSearch={handleSearch}
      />
    </div>
  );
}

export default function Billing() {
  const { userEmail } = useSelector((state) => state.generalData);
  const { t } = useTranslation();

  return (
    <CustomAccordian
      style={{
        fontSize: "1.5rem",
        fontWeight: "700",
        lineHeight: "130%",
        letterSpacing: "1.008px"
      }}
      title={t("billing.title")}
      expanded
    >
      <BillingHistory email={userEmail} />
    </CustomAccordian>
  );
}
