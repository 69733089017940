/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MultiBarChartComponent from "./components/MultiBarChartComponent";
import StackedChartComponent from "./components/StackedChartComponent";
import AgeBarGraph from "./components/AgeBarGraph";
import SpendBarGraph from "./components/SpendBarGraph";
import CategoriesBarGraph from "./components/CategoriesBarGraph";
import RoomsBarGraph from "./components/RoomsBarGraph";
import CitiesBarGraph from "./components/CitiesBarGraph";
import CountriesBarGraph from "./components/CountriesBarGraph";
import PageUrlBarGraph from "./components/PageUrlBarGraph";
import WorldMap from "../../../components/ChartComponents/WorldMap";
import LineChart from "../../DashBoard/DashBoardLandingPage/LineChart";
import DonutChart from "../../../components/ChartComponents/DonutChart";
import NoDataFound from "../../Placeholders/NoDataFound";
import PersonaLabel from "../../../components/PersonaLabel";
import Content from "./components/Content";
import SourceBarGraph from "./components/SourceBarGraph";
import MediumBarGraph from "./components/MediumBarGraph";
import CampaignBarGraph from "./components/CampaignBarGraph";
import { findPersonaDescriptionDashboard } from "../../../utils/findPersonaDescription";
import TermBarGraph from "./components/TermBarGraph";
import ContentBarGraph from "./components/ContentBarGraph";

export default function Index({
  dashboard,
  isCompare,
  selectedPersonaFilters,
  isToggled,
  updateSelectedPersonasFilters
}) {
  const {
    allData,
    personasData,
    countriesData,
    worldData,
    viewsVisitorsData,
    categoriesData,
    ageData,
    genderData,
    citiesData,
    totalSpendData,
    visitorsLineData,
    roomsData,
    pageUrlData,
    sourceData,
    mediumData,
    campaignData,
    termData,
    contentData
  } = useSelector((state) => state.PersonasData);

  const { t } = useTranslation();

  const commonProps = {
    dashboard,
    isCompare,
    filterValues: selectedPersonaFilters
  };

  const personaDescription = findPersonaDescriptionDashboard(
    allData,
    commonProps.filterValues,
    t
  );

  if (allData.length === 0) {
    return <NoDataFound />;
  }

  return (
    <>
      {isToggled && (
        <MultiBarChartComponent
          visitorsLineData={visitorsLineData}
          commonProps={commonProps}
          t={t}
        />
      )}

      <StackedChartComponent
        personasData={personasData}
        updateSelectedPersonasFilters={updateSelectedPersonasFilters}
        selectedPersonaFilters={selectedPersonaFilters}
        t={t}
      />
      <PersonaLabel personaDescription={personaDescription} />

      {isToggled && (
        <div className="grid half-column-grid">
          <AgeBarGraph ageData={ageData} commonProps={commonProps} t={t} />
          <DonutChart
            title={t("personaDashboard.gender")}
            labels={genderData?.labels}
            dataSet={genderData?.visitors}
            selectedFilters={selectedPersonaFilters}
            updateFilters={updateSelectedPersonasFilters}
          />
        </div>
      )}

      {isToggled && (
        <div className="grid half-column-grid">
          <SpendBarGraph
            totalSpendData={totalSpendData}
            commonProps={commonProps}
            t={t}
          />

          <RoomsBarGraph
            roomsData={roomsData}
            commonProps={commonProps}
            t={t}
          />
        </div>
      )}

      <div className={isToggled ? "grid half-column-grid" : "grid"}>
        {isToggled && (
          <CitiesBarGraph
            citiesData={citiesData}
            commonProps={commonProps}
            t={t}
          />
        )}
        <CountriesBarGraph
          countriesData={countriesData}
          commonProps={commonProps}
          t={t}
        />
      </div>

      {!isToggled && (
        <div className="grid">
          <WorldMap
            data={worldData?.dataset}
            labels={worldData.labels}
            title={t("analyticsPage.worldTitle")}
            selectedFilters={selectedPersonaFilters}
            updateFilters={updateSelectedPersonasFilters}
          />
        </div>
      )}

      <div className="grid">
        <LineChart
          views={viewsVisitorsData?.views}
          visitors={viewsVisitorsData?.visitors}
          labels={viewsVisitorsData?.labels}
          title={t("analyticsPage.visitorsPageViewsTitle")}
        />
      </div>

      <div className="grid">
        <CategoriesBarGraph
          categoriesData={categoriesData}
          commonProps={commonProps}
          t={t}
        />
      </div>

      <Content />

      {isToggled && (
        <PageUrlBarGraph
          pageUrlData={pageUrlData}
          commonProps={commonProps}
          t={t}
        />
      )}
      <div className="grid grid-template">
        <div className="general-bar-graph-height">
          <SourceBarGraph
            sourceData={sourceData}
            commonProps={commonProps}
            t={t}
          />
        </div>
        <div className="general-bar-graph-height">
          <MediumBarGraph
            mediumData={mediumData}
            commonProps={commonProps}
            t={t}
          />
        </div>
        <div className="general-bar-graph-height">
          <CampaignBarGraph
            campaignData={campaignData}
            commonProps={commonProps}
            t={t}
          />
        </div>
      </div>

      <div className="grid half-column-grid">
        <div className="general-bar-graph-height">
          <TermBarGraph termData={termData} commonProps={commonProps} t={t} />
        </div>
        <div className="general-bar-graph-height">
          <ContentBarGraph
            contentData={contentData}
            commonProps={commonProps}
            t={t}
          />
        </div>
      </div>
    </>
  );
}

Index.propTypes = {
  dashboard: PropTypes.string,
  isCompare: PropTypes.bool,
  selectedPersonaFilters: PropTypes.oneOfType([PropTypes.shape({})]),
  isToggled: PropTypes.bool,
  updateSelectedPersonasFilters: PropTypes.func
};
