/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomAccordian from "../../../../components/Accordion/CustomAccordian";
import Input from "../../../../components/inputElements/Input/Input";
import logOut from "../../../../assets/icons/logOut.svg";
import editButton from "../../../../assets/icons/edit.svg";
import DeleteAccount from "../../../../assets/icons/DeleteAccount.svg";
import "../assets/stylesheets/profileSettings.scss";
import {
  ButtonDark,
  ButtonLight
} from "../../../../components/inputElements/buttons/MainButton";
import {
  deleteProfile,
  getProfileSettings,
  resetPasswordSettings,
  updateProfileSettings
} from "../api/profileApi";
import { Toastr } from "../../../../components/Toastr/Toastr";
import PasswordInput from "../../../../components/inputElements/Input/Password";
import ConfirmPasswordInput from "../../../../components/inputElements/Input/ConfirmPassword";

function LabelInputSet({ children }) {
  return <div className="flex-column">{children}</div>;
}

export default function Profile() {
  const { t } = useTranslation();
  const { websiteID } = useSelector((state) => state.generalData);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      emailID: ""
    }
  });

  const navigate = useNavigate();

  const {
    register: registerPassword,
    formState: { errors: errorPassword },
    handleSubmit: handleSubmitPassword,
    reset: resetingPassword,
    watch,
    setError
  } = useForm({
    defaultValues: {
      passwordCurrent: "",
      passwordNew: "",
      passwordRenter: ""
    }
  });

  const [editProfile, setEditProfile] = useState(true);
  const [clickExpand, setClickExpand] = useState(false);
  const [profileData, setProfileData] = useState([]);
  console.log("profileData", profileData);

  const { role: userRole } = useSelector((state) => state.generalData);

  const getProfileSettingsData = async () => {
    try {
      const res = await getProfileSettings();
      setProfileData(res?.data?.result);
      const { firstName } = res.data.result;
      console.log(res.data.result);
      reset({ ...res.data.result });
      if (firstName) setEditProfile(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    // window.location.href = "/";
    navigate("/");
  };

  const resetPassword = async (data) => {
    try {
      const res = await resetPasswordSettings({
        old_password: data.passwordCurrent,
        password: data.passwordNew
      });
      if (res.data.status === "ok") {
        Toastr.success("reseted password successfully");
        setClickExpand(false);
        resetingPassword({
          passwordCurrent: "",
          passwordNew: "",
          passwordRenter: ""
        });
      }
    } catch (error) {
      console.log(error);
      setError("passwordCurrent", {
        message: "Old Password is wrong"
      });
    }
  };
  const apiCall = async (data) => {
    console.log(data);
    try {
      const formData = new FormData();
      formData.append("firstName", data.firstName);
      formData.append("lastName", data.lastName);

      const res = await updateProfileSettings(formData);
      if (res.success) {
        Toastr.success("Updated Profile Settings");
        getProfileSettingsData();
        setEditProfile(false);
        reset({
          firstName: "",
          lastName: ""
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteProfile = async () => {
    try {
      await deleteProfile();
      handleLogout();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProfileSettingsData();
  }, [websiteID]);

  return (
    <div className="profile-settings">
      <div className="settings-title">
        {t("profileSettings.generalInformation.title")}
      </div>
      {!editProfile && (
        <div className="flex-end editButtonTop">
          <button
            type="button"
            className="buttonEdit"
            onClick={() => {
              setEditProfile(true);
            }}
          >
            <img src={editButton} alt="edit" />
          </button>
        </div>
      )}

      <CustomAccordian
        expanded
        title={t("profileSettings.generalInformation.title")}
      >
        <form
          onSubmit={handleSubmit(
            (data) => apiCall(data),
            () => {
              console.log("errors...", errors);
            }
          )}
        >
          <div className="grid-gap">
            <div className="half-column-grid">
              <LabelInputSet>
                <label htmlFor="first-name" className="label-tag">
                  {t("profileSettings.generalInformation.labels.firstName")}
                  <span className="asterisk">*</span>
                </label>
                {editProfile ? (
                  <Input
                    type="text"
                    id="first-name"
                    name="firstName"
                    register={register("firstName", {
                      required: true
                    })}
                    error={
                      errors.firstName &&
                      `* ${t(
                        "profileSettings.generalInformation.labels.firstName"
                      )} ${t("form.validation.required")}`
                    }
                  />
                ) : (
                  <div className="input-view add-tag-input-label">
                    {getValues("firstName")}
                  </div>
                )}
              </LabelInputSet>

              <LabelInputSet>
                <label htmlFor="last-name" className="label-tag">
                  {t("profileSettings.generalInformation.labels.lastName")}
                  <span className="asterisk">*</span>
                </label>
                {editProfile ? (
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    register={register("lastName", {
                      required: true
                    })}
                    error={
                      errors.lastName &&
                      `* ${t(
                        "profileSettings.generalInformation.labels.lastName"
                      )} ${t("form.validation.required")}`
                    }
                  />
                ) : (
                  <div className="input-view add-tag-input-label">
                    {getValues("lastName")}
                  </div>
                )}
              </LabelInputSet>
            </div>
            <div className="">
              <LabelInputSet>
                <label htmlFor="email-id" className="label-tag">
                  {t("profileSettings.generalInformation.labels.emailID")}
                </label>
                {editProfile ? (
                  <Input
                    type="email"
                    name="emailID"
                    id="email-id"
                    disabled
                    value={getValues("emailID")}
                  />
                ) : (
                  <div className="input-view add-tag-input-label">
                    {getValues("emailID")}
                  </div>
                )}
              </LabelInputSet>
            </div>
            {editProfile ? (
              <div className="flex-center">
                <ButtonLight
                  buttonText={t(
                    "profileSettings.generalInformation.buttons.cancel"
                  )}
                  onClick={() => setEditProfile(false)}
                />
                <ButtonDark
                  isSubmit
                  buttonText={t(
                    "profileSettings.generalInformation.buttons.save"
                  )}
                  className="buttonSpacing"
                />
              </div>
            ) : (
              <div> </div>
            )}
          </div>
        </form>
      </CustomAccordian>
      <CustomAccordian
        title={t("profileSettings.passwordSettings.title")}
        clickExpand={clickExpand}
        setClickExpand={() => setClickExpand(!clickExpand)}
      >
        <div>
          <div className="reset-pwd">
            {t("profileSettings.passwordSettings.resetPassword")}
          </div>
          <form onSubmit={handleSubmitPassword(resetPassword)}>
            <div className="grid-gap">
              <LabelInputSet>
                <label htmlFor="passwordCurrent" className="label-tag">
                  {t("profileSettings.passwordSettings.labels.currentPassword")}
                </label>
                <PasswordInput
                  name="passwordCurrent"
                  id="passwordCurrent"
                  register={registerPassword("passwordCurrent", {
                    required: true
                  })}
                  error={
                    errorPassword.passwordCurrent &&
                    (errorPassword.passwordCurrent.message
                      ? errorPassword.passwordCurrent.message
                      : `* ${t(
                          "profileSettings.passwordSettings.labels.currentPassword"
                        )} ${t("form.validation.required")}`)
                  }
                />
              </LabelInputSet>
              <LabelInputSet>
                <label htmlFor="passwordNew" className="label-tag">
                  {t("profileSettings.passwordSettings.labels.newPassword")}
                </label>
                <PasswordInput
                  name="passwordNew"
                  id="passwordNew"
                  register={registerPassword("passwordNew", {
                    required: true,
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/,
                      message: t(
                        "profileSettings.passwordSettings.messages.passwordRequirements"
                      )
                    }
                  })}
                  error={
                    errorPassword.passwordNew &&
                    (errorPassword.passwordNew.message
                      ? errorPassword.passwordNew.message
                      : `* ${t(
                          "profileSettings.passwordSettings.labels.newPassword"
                        )} ${t("form.validation.required")}`)
                  }
                />
              </LabelInputSet>
              <LabelInputSet>
                <label htmlFor="passwordRenter" className="label-tag">
                  {t("profileSettings.passwordSettings.labels.reenterPassword")}
                </label>
                <ConfirmPasswordInput
                  name="passwordRenter"
                  id="passwordRenter"
                  register={registerPassword("passwordRenter", {
                    required: true,
                    validate: (val) => {
                      if (watch("passwordNew") !== val) {
                        return t(
                          "profileSettings.passwordSettings.messages.passwordMismatch"
                        );
                      }
                      return null;
                    }
                  })}
                  error={
                    errorPassword.passwordRenter &&
                    (errorPassword.passwordRenter.message
                      ? errorPassword.passwordRenter.message
                      : `* ${t(
                          "profileSettings.passwordSettings.labels.reenterPassword"
                        )} ${t("form.validation.required")}`)
                  }
                />
              </LabelInputSet>
              <div className="flex-center reset-pwd">
                <ButtonLight
                  buttonText={t(
                    "profileSettings.passwordSettings.buttons.cancel"
                  )}
                  onClick={() => setClickExpand(false)}
                />
                <ButtonDark
                  buttonText={t(
                    "profileSettings.passwordSettings.buttons.save"
                  )}
                  className="buttonSpacing"
                  isSubmit
                />
              </div>
            </div>
          </form>
        </div>
      </CustomAccordian>
      <div className="flex-column">
        <button
          className="log-out flex-center"
          type="button"
          onClick={handleLogout}
        >
          {t("profileSettings.logOut")}
          <img src={logOut} alt="logOut" />
        </button>
        {userRole === 1 || userRole === 7 ? (
          <button
            className="delete-account flex-center"
            type="button"
            onClick={handleDeleteProfile}
          >
            {t("profileSettings.deleteAccount")}
            <img src={DeleteAccount} alt="deleteAccount" />
          </button>
        ) : null}
      </div>
    </div>
  );
}

LabelInputSet.propTypes = {
  children: PropTypes.element
};
