import { useTranslation } from "react-i18next";
import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import DataExportComponent from "../../features/DataExport";

export default function DataExport() {
  const { t } = useTranslation();
  return (
    <div>
      <AuthorizedHeader />
      <div className="dashboard-body">
        <div className="container">
          <div className="flex-between">
            <div className="heading3" style={{ padding: "1rem" }}>
              {t("integrationMenu.dataExport")}
            </div>
          </div>
          <DataExportComponent />
        </div>
      </div>
    </div>
  );
}
