/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import SliderButton from "../../../../components/inputElements/buttons/SliderButton";
import mailUnread from "../../../../assets/icons/mailIcon.svg";
import mailRead from "../../../../assets/icons/mailRead.svg";
import "../assets/stylesheet/notificationCard.scss";
import axios from "../../../../api/axios";
import { updateScrollArchive } from "../../../../store/slices/generalSlice";
import notificationMapping from "../../../../utils/notificationMappings";
import getNotificationInfo from "../../../../utils/getNotificationInfo";
import formatElapsedTime from "../../../../utils/formatElapsedTime";

function NotificationCard({ notificationData }) {
  return (
    <div className="notification-main">
      {notificationData.map((ele) => {
        return (
          <div className="notification-card">
            <div className="notification-header">{ele.header}</div>
            <hr className="breakCard" />
            <div className="grid-notification">
              <div className="flex-column-center">
                <div className="notification-title">{ele.title}</div>
                <div className="notification-body">{ele.body}</div>
              </div>
              <div className="flex-column-end">
                {ele.notificationButton && <SliderButton name="Notification" />}
                {ele.emailButton && <SliderButton name="email" />}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default function Notification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { websiteID } = useSelector((state) => state.generalData);

  const { scrollArchive, createdBy: customerId } = useSelector(
    (state) => state.generalData
  );
  const [archivedNotifications, setArchivedNotifications] = useState([]);

  //   [
  //     {
  //       header: "User",
  //       title: "New User Created",
  //       body: "You will receive notifications whenever a new user is added to this website.",
  //       notificationButton: true,
  //       emailButton: true
  //     },
  //     {
  //       title: "Role of user",
  //       body: "You will receive notifications whenever  a role is assigned or changed of any user on this website.",
  //       notificationButton: true,
  //       emailButton: true
  //     },
  //     {
  //       title: "User removed",
  //       body: "You will receive notifications whenever a user is removed from this website.",
  //       notificationButton: true,
  //       emailButton: true
  //     }
  //   ],
  //   [
  //     {
  //       header: "Reports (metrics)",
  //       title: "Daily reports",
  //       body: "You will receive a report about the performance of the page on daily basis.",
  //       emailButton: true
  //     },
  //     {
  //       title: "Weekly reports",
  //       body: "You will receive a report about the performance of the page on weekly basis.",
  //       emailButton: true
  //     },
  //     {
  //       title: "Monthly reports",
  //       body: "You will receive a report about the performance of the page on monthly basis.",
  //       emailButton: true
  //     }
  //   ],
  //   [
  //     {
  //       header: "Campaigns",
  //       title: "General",
  //       body: "You will receive notifications whenever any campaign is started, paused, resumed or removed.",
  //       notificationButton: true,
  //       emailButton: true
  //     }
  //   ],
  //   [
  //     {
  //       header: "Widgets",
  //       title: "General",
  //       body: "You will receive notifications whenever any widget is added or removed.",
  //       notificationButton: true,
  //       emailButton: true
  //     }
  //   ]
  // ];

  const mappedNotifications = notificationMapping(t);

  const notificationApi = async () => {
    try {
      console.log(customerId);
      const res = await axios.post("/vivaWallet/notification", {
        customer_id: customerId
      });
      setArchivedNotifications(res.data.notificationRead);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    notificationApi();
  }, [websiteID]);

  useEffect(() => {
    if (scrollArchive) {
      const scrollEle = document.getElementsByClassName("notiArchive")[0];
      console.log(scrollEle);
      scrollEle.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
    dispatch(updateScrollArchive(false));
  }, [scrollArchive]);

  return (
    <div>
      <div className="settings-title">{t("notification.notification")}</div>
      <div className="notification-subtitle">{t("notification.details")}</div>

      {archivedNotifications && (
        <div className="notification-main">
          <div className="notification-card">
            <div className="notification-header notiArchive">
              {t("notification.archive")}
            </div>
            <hr className="breakCard" />
            <div className="notification-list">
              {archivedNotifications.map((notification) => (
                <>
                  <div
                    className={`notification-item ${
                      notification.read && "read"
                    }`}
                  >
                    <div className="flex">
                      {notification.read ? (
                        <img src={mailRead} alt="notificationIcon" />
                      ) : (
                        <img src={mailUnread} alt="notificationIcon" />
                      )}
                      <div>
                        <div className="notificationText">
                          {
                            getNotificationInfo(
                              notification.notificationText,
                              mappedNotifications
                            ).text
                          }
                        </div>
                        <div className="notificationBody">
                          {t("notification.goto")}{" "}
                          <span
                            className="link"
                            onClick={() =>
                              navigate(
                                getNotificationInfo(
                                  notification.notificationText,
                                  mappedNotifications
                                ).link
                              )
                            }
                          >
                            {
                              getNotificationInfo(
                                notification.notificationText,
                                mappedNotifications
                              ).body
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="notification-time">
                      {" "}
                      {formatElapsedTime(notification.time, t)}{" "}
                    </div>
                  </div>
                  <hr className="notification-bottom" />
                </>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

NotificationCard.propTypes = {
  notificationData: PropTypes.oneOfType([PropTypes.array])
};

Notification.propTypes = {};
