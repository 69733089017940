/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import AddBarGraph from "../../../../components/ChartComponents/GeneralBarGraph";

export default function SourceBarGraph({ sourceData, commonProps, t }) {
  return (
    <AddBarGraph
      title={t("personaDashboard.source")}
      labels={sourceData?.labels}
      numBars={sourceData?.labels?.length < 6 ? sourceData?.labels?.length : 6}
      data={{
        tooltip: t("analyticsPage.visitors"),
        values: sourceData?.counts
      }}
      columnData={[
        {
          name: t("analyticsPage.visitors"),
          diff: sourceData?.counts,
          number: true
        }
      ]}
      {...commonProps}
    />
  );
}

const commonPropsShape = PropTypes.shape({
  dashboard: PropTypes.string,
  isCompare: PropTypes.bool,
  filterValues: PropTypes.oneOfType([PropTypes.shape({})])
});

SourceBarGraph.propTypes = {
  sourceData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    counts: PropTypes.arrayOf(PropTypes.number)
  }),
  commonProps: commonPropsShape.isRequired,
  t: PropTypes.func.isRequired
};
