/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable */
import { useState, React, useEffect, useCallback } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import sort from "../../assets/icons/sort.svg";
import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import DataTable from "../../components/Table/DataTable";
import themeColors from "../../assets/stylesheets/_var.scss";
import "../stylesheets/tagManager.scss";
import AddTagManager from "../../features/TagManager/AddTagManager";
import axios from "../../api/axios";
import {
  ButtonDark,
  ButtonLight
} from "../../components/inputElements/buttons/MainButton";
import { Toastr } from "../../components/Toastr/Toastr";
import DeleteComponent from "../../components/DeletePopUp/DeleteComponent";
import CustomTagComponent from "../Components/CustomTagComponent";
import {
  addCustomTag,
  deleteCustomLabelTag,
  getCustomTagsAPI
} from "../../features/TagManager/AddTagManager/api/tagManagerApi";
import Input from "../../components/inputElements/Input/Input";
import closeButton from "../../assets/icons/Close.svg";

function CustomButton() {
  return (
    <IconButton>
      <img src={sort} alt="sort" />
    </IconButton>
  );
}

const columns = [
  {
    field: "event_title",
    headerName: "Name",
    flex: 1
  },
  { field: "tag_type", headerName: "tagType", flex: 1 },
  { field: "sales_event", headerName: "tagGroup", flex: 1 },
  { field: "created_date", headerName: "Created Date", flex: 1 },
  { field: "updated_date", headerName: "Update Date", flex: 1 }
];

export default function TagManager() {
  const [showAddTagManager, setShowAddTagManager] = useState(false);
  const [showAddCustomTagManager, setShowCustomAddTagManager] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState("event_title");
  const [inputText, setInputText] = useState("");
  const [customTagList, setCustomTagList] = useState([]);
  const [selectedCustomTags, setSelectedCustomTags] = useState([]);
  const [selectedCustomTagsInfo, setSelectedCustomTagsInfo] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showTagDeletePopup, setShowTagDeletePopup] = useState(false);
  const [tagData, setTagData] = useState([]);
  const [filteredTagData, setFilteredTagData] = useState([]);
  const [rowClicked, setRowClicked] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectMultipleRowData, setSelectMultipleRowData] = useState([]);
  const { websiteID } = useSelector((state) => state.generalData);
  console.log("websiteId====>", websiteID);
  const websiteId = websiteID;

  const { t } = useTranslation();

  const searchFilters = [
    {
      name: t("tagManager.Name"),
      value: "event_title"
    },
    {
      name: t("tagManager.tagType"),
      value: "tag_type"
    },
    {
      name: t("tagManager.tagGroup"),
      value: "sales_event"
    }
  ];

  const getCustomTags = async () => {
    setLoading(true);
    try {
      setLoading(false);
      const res = await getCustomTagsAPI(websiteId);
      setCustomTagList(res.data.result);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleCheckboxChange = (isChecked, salesEventId, customTagInfo) => {
    setSelectedCustomTags((prevSelectedTags) =>
      isChecked
        ? [...prevSelectedTags, { sales_event_id: salesEventId }]
        : prevSelectedTags.filter((tag) => tag.sales_event_id !== salesEventId)
    );
    setSelectedCustomTagsInfo((prevSelectedTagsInfo) =>
      isChecked
        ? [...prevSelectedTagsInfo, customTagInfo]
        : prevSelectedTagsInfo.filter(
            (tag) => tag.sales_event_id !== salesEventId
          )
    );
  };

  const fetchData = () => {
    setLoading(true);
    try {
      axios.get(`tag/getWebsiteEventTag/${websiteId}`).then((res) => {
        setTagData(res.data.result);
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRowsSelect = (selectedData) => {
    setSelectMultipleRowData(selectedData);
  };

  const handleRowDelete = () => {
    console.log(selectMultipleRowData);
    if (selectMultipleRowData !== null && selectMultipleRowData.length > 0) {
      setShowDeletePopup(true);
    } else {
      Toastr.error("Please select a tag to delete from the list");
    }
  };

  const confirmDeleteRows = () => {
    const deleteArray = selectMultipleRowData.map((ele) => {
      return {
        website_event_relation_id: ele.website_event_relation_id
      };
    });
    axios
      .delete("tag/deleteWebsiteEventTag", {
        data: deleteArray
      })
      .then((res) => {
        if (res.data.success === true) {
          fetchData();
          Toastr.success(t("tagManager.deletedTagsSuccess"));
        }
      });
  };

  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowAddTagManager(true);
    setRowClicked(true);
    console.log(rowData);
  };

  const handleShowAddTag = async () => {
    setShowAddTagManager(!showAddTagManager);
    setRowClicked(false);
    await getCustomTags();
  };

  const handleShowCustomLabelPopup = () => {
    setShowCustomAddTagManager(true);
  };

  const handleInputChange = (e) => setInputText(e.target.value);

  const handleAddOption = async () => {
    if (inputText == "") {
      Toastr.error(t("customTags.addValidation"));
    } else {
      setLoading(true);
      try {
        if (inputText.trim()) {
          const res = await addCustomTag({
            sales_custom_tag: inputText,
            website_id: websiteId
          });
          Toastr.success(t("tagManager.successMessages.addedCustomTag"));
          setCustomTagList((prevOptions) => [res.data.result, ...prevOptions]);
          setInputText(""); // optionally clear the input
          setShowCustomAddTagManager(false);
          setLoading(false);
          await getCustomTags();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDeleteCustomTag = async () => {
    setLoading(true);
    try {
      const res = await deleteCustomLabelTag({
        website_id: websiteID,
        selectedId: selectedCustomTags
      });
      Toastr.success(t("tagManager.deletedTagsSuccess"));
      setSelectedCustomTags([]);
      setShowTagDeletePopup(false);
      setLoading(false);
      await getCustomTags();
      await fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (searchTerm) => {
    const filteredResults = [];
    if (searchTerm?.length >= 1) {
      tagData?.forEach((tag) => {
        const value = tag[searchFilter];
        const lowerCaseValue = value?.toLowerCase?.();
        const lowerCaseSearchTerm = searchTerm?.toLowerCase();
        const startsWithCondition =
          lowerCaseValue.startsWith(lowerCaseSearchTerm);
        const includesCondition = lowerCaseValue.includes(lowerCaseSearchTerm);
        const exactMatchCondition = lowerCaseValue === lowerCaseSearchTerm;
        if (startsWithCondition) {
          filteredResults.unshift(tag); // Add exact matches to the beginning
        } else if (exactMatchCondition) {
          filteredResults.push(tag); // Add starts with matches after exact matches
        } else if (includesCondition) {
          filteredResults.push(tag); // Add includes matches last
        }
      });
    } else {
      filteredResults.push(...tagData);
    }
    setFilteredTagData(filteredResults);
  };

  useEffect(() => {
    fetchData();
    getCustomTags();
  }, [websiteID]);

  console.log("filteredTagData====>", filteredTagData, "loading====>", loading);

  return (
    <>
      <AuthorizedHeader />
      <div className="container tagManager">
        {/* Custom Tags List */}
        {!showAddTagManager && (
          <div>
            <div
              style={{
                marginTop: "1%"
              }}
              className="flex-center"
            >
              <div
                style={!isMobile ? { width: "100%" } : {}}
                className="tagMain flex-between"
              >
                <div className="tag-manager-heading">
                  {t("customTags.tagGroups")}
                </div>
                <div className="flex">
                  <button
                    style={
                      !isMobile
                        ? { backgroundSize: "50px" }
                        : { backgroundSize: "40px" }
                    }
                    className="delete-multiple"
                    type="button"
                    onClick={() => {
                      if (selectedCustomTags?.length > 0) {
                        setShowTagDeletePopup(true);
                      } else {
                        Toastr.error(t("customTags.deleteValidation"));
                      }
                    }}
                  />
                  {showTagDeletePopup && (
                    <DeleteComponent
                      onCancel={() => setShowTagDeletePopup(false)}
                      loading={loading}
                      onConfirmDelete={() => {
                        handleDeleteCustomTag();
                      }}
                    />
                  )}
                  <ButtonDark
                    buttonText={t("customTags.addTagGroup") + " +"}
                    onClick={handleShowCustomLabelPopup}
                  />
                </div>
              </div>
            </div>

            {showAddCustomTagManager ? (
              <div className="add-tag-form-box">
                {loading ? (
                  <div className="flex-center">
                    <TailSpin
                      height="50"
                      width="50"
                      color={themeColors.primaryColor}
                      ariaLabel="tail-spin-loading"
                      radius="2"
                      wrapperStyle={{}}
                      wrapperClass="spinner"
                      visible
                    />
                  </div>
                ) : (
                  <div>
                    <div className="flex-between">
                      <div className="add-tag-title">
                        {t("customTags.addTagGroup")}
                      </div>
                      <div className="flex">
                        <button
                          className="close-button"
                          onClick={() => {
                            setShowCustomAddTagManager(false);
                          }}
                          type="button"
                        >
                          <img src={closeButton} alt="close-button" />
                        </button>
                      </div>
                    </div>

                    <form>
                      <div className="grid add-tag-form-grid">
                        <div className="flex-column">
                          <label htmlFor="tag-title" className="label-tag">
                            {t("customTags.tagGroups")}
                          </label>
                          <Input
                            type="text"
                            value={inputText}
                            name="tagTitle"
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="flex-end">
                          <ButtonLight
                            buttonText={t("common.cancelButton")}
                            onClick={() => {
                              setShowCustomAddTagManager(false);
                            }}
                          />
                          <ButtonDark
                            buttonText={t("common.saveButton")}
                            className="tagManagerButton"
                            onClick={handleAddOption}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex-center">
                <div className="background-box custom-tags">
                  {loading ? (
                    <div className="flex-center">
                      <TailSpin
                        height="50"
                        width="50"
                        color={themeColors.primaryColor}
                        ariaLabel="tail-spin-loading"
                        radius="2"
                        wrapperStyle={{}}
                        wrapperClass="spinner"
                        visible
                      />
                    </div>
                  ) : (
                    customTagList?.map((customTag, index) => {
                      return (
                        <CustomTagComponent
                          key={index}
                          isConnected={customTag?.isConnected}
                          customTag={customTag?.sales_event}
                          customTagInfo={customTag}
                          salesEventId={customTag?.sales_event_id}
                          onCheckboxChange={handleCheckboxChange}
                          isSelected={selectedCustomTags.some(
                            (selectedTag) =>
                              selectedTag.sales_event_id ===
                              customTag.sales_event_id
                          )}
                        />
                      );
                    })
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {/* Custom Tags List end */}

        {/* Tags list */}
        <div className="flex-center">
          <div
            style={!isMobile ? { width: "100%" } : {}}
            className="flex-between"
          >
            <div className="tag-manager-heading">
              {rowClicked || (!showAddTagManager && t("tagManager.tagManager"))}
            </div>

            {!showAddTagManager && (
              <div className="flex">
                {!showAddTagManager && (
                  <div className="flex">
                    <button
                      style={
                        !isMobile
                          ? { backgroundSize: "50px" }
                          : { backgroundSize: "40px" }
                      }
                      className="delete-multiple"
                      type="button"
                      onClick={handleRowDelete}
                    />
                    {showDeletePopup && (
                      <DeleteComponent
                        loading={false}
                        onCancel={() => setShowDeletePopup(false)}
                        onConfirmDelete={() => {
                          setShowDeletePopup(false);
                          confirmDeleteRows();
                        }}
                      />
                    )}
                    <ButtonDark
                      buttonText={t("tagManager.addTagButton")}
                      onClick={handleShowAddTag}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            paddingTop: "27px"
          }}
          className="flex-center"
        >
          {showAddTagManager ? (
            <AddTagManager
              initialData={rowClicked ? selectedRowData : " "}
              rowClicked={rowClicked}
              handleShowAddTag={handleShowAddTag}
              fetchData={fetchData}
            />
          ) : (
            <>
              <DataTable
                getRowId={(row) => row.website_event_relation_id}
                rows={filteredTagData?.length > 0 ? filteredTagData : tagData}
                pageSize={7}
                CustomButton={CustomButton}
                onRowsSelect={handleRowsSelect}
                onRowClick={handleRowClick}
                tableType={1}
                columns={columns.map((col) => ({
                  ...col,
                  headerName: t(`tagManager.${col.headerName}`)
                }))}
                search
                searchFilters={searchFilters}
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                handleSearch={handleSearch}
              />
            </>
          )}
        </div>
        {/* Tags list ends */}
      </div>
    </>
  );
}
