const separateDataByKey = (data, key) => {
  const result = data.reduce((acc, item) => {
    const value = item[key];
    if (value) {
      if (acc[value]) {
        acc[value] += 1;
      } else {
        acc[value] = 1;
      }
    }
    return acc;
  }, {});

  // Combine labels and counts into an array of objects
  const combinedData = Object.keys(result).map((label) => ({
    label,
    count: result[label]
  }));

  // Sort the combined data by count in descending order
  combinedData.sort((a, b) => b.count - a.count);

  // Separate the sorted data back into labels and counts
  const labels = combinedData.map((item) => item.label);
  const counts = combinedData.map((item) => item.count);

  return { labels, counts };
};

export default separateDataByKey;
