/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Input from "../../../../components/inputElements/Input/Input";
import {
  ButtonLight,
  ButtonDark
} from "../../../../components/inputElements/buttons/MainButton";
import CloseIcon from "../../../../assets/icons/closeModal.svg";
import CopyInput from "../../../../components/inputElements/Input/CopyInput";
import RegenaratePassword from "./RegenaratePassword";
import asaServerUrl from "../../../../data/AsaServerUrl";
import "../stylesheets/edit.scss";

export default function EditApi({
  setActiveManageComponent,
  selectedRowData,
  showRegeneratePasswordModal,
  handleRegeneratePasswordClick,
  regenaratePassword,
  setRegenaratePassword,
  handleSave,
  userName,
  loading,
  token,
  regenarateToken,
  setRoomNumber,
  roomNumber
}) {
  const { t } = useTranslation();
  return (
    <section className="edit-api-section">
      <div className="close-icon" onClick={() => setActiveManageComponent(0)}>
        <img src={CloseIcon} alt="close-icon" />
      </div>
      <h3 className="heading">{t("ManageApi.editApi.edit")}</h3>
      <form>
        <div className="form-group">
          <label htmlFor="selectService">
            {t("ManageApi.editApi.selectedService")}
          </label>
          <div className="gap-20">
            <Input
              type="text"
              value={selectedRowData?.apiName}
              readOnlyCondition
            />
          </div>
          {selectedRowData?.apiName === "ASA" && (
            <div className="gap-20">
              <Input
                type="number"
                value={roomNumber}
                label={t("ManageApi.editApi.roomNumber")}
                onChange={(e) => setRoomNumber(e.target.value)}
              />
            </div>
          )}
        </div>
        <div className="generated-credentials">
          <div className="heading-details">
            <h3 className="heading">{t("ManageApi.createAPi.heading")}</h3>
          </div>
          {selectedRowData?.apiName === "ASA" ? (
            <div className="credentails">
              <div className="gap-20">
                <CopyInput
                  type="text"
                  label={t("ManageApi.createAPi.serverUrl")}
                  value={asaServerUrl}
                  readOnlyCondition
                />
              </div>
              <div className="gap-20">
                <CopyInput
                  type="text"
                  label={t("ManageApi.createAPi.user")}
                  value={userName}
                  readOnlyCondition
                />
              </div>
              <div>
                <CopyInput
                  type="text"
                  label={t("ManageApi.createAPi.password")}
                  value={regenaratePassword}
                  readOnlyCondition
                />
                <p
                  className="regenrate-password"
                  onClick={handleRegeneratePasswordClick}
                >
                  {t("ManageApi.editApi.regenaratePassword")}
                </p>
              </div>
            </div>
          ) : (
            <div className="credentails isi">
              <div className="gap-20">
                <CopyInput
                  type="text"
                  label={t("ManageApi.token")}
                  value={token}
                  readOnlyCondition
                />
                <p className="regenrate-token" onClick={regenarateToken}>
                  {t("ManageApi.editApi.regenrateToken")}
                </p>
              </div>
            </div>
          )}
          {selectedRowData?.apiName === "ASA" ? (
            <div className="button-combination flex">
              <ButtonLight
                buttonText={t("common.cancelButton")}
                onClick={() => setActiveManageComponent(0)}
              />
              <ButtonDark
                buttonText={
                  loading ? t("common.loading") : t("common.saveButton")
                }
                onClick={handleSave}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </form>
      {showRegeneratePasswordModal && <div className="overlay" />}
      {showRegeneratePasswordModal && (
        <div className="regenarate-modal">
          <RegenaratePassword
            setRegenaratePassword={setRegenaratePassword}
            handleRegeneratePasswordClick={handleRegeneratePasswordClick}
          />
        </div>
      )}
    </section>
  );
}

EditApi.propTypes = {
  setActiveManageComponent: PropTypes.func,
  selectedRowData: PropTypes.shape({
    id: PropTypes.number,
    apiName: PropTypes.string,
    status: PropTypes.number,
    roomNumber: PropTypes.number,
    apiServiceId: PropTypes.number
  }),
  showRegeneratePasswordModal: PropTypes.bool,
  handleRegeneratePasswordClick: PropTypes.func,
  regenaratePassword: PropTypes.string,
  setRegenaratePassword: PropTypes.func,
  handleSave: PropTypes.func,
  userName: PropTypes.string,
  loading: PropTypes.bool,
  token: PropTypes.string,
  regenarateToken: PropTypes.func,
  setRoomNumber: PropTypes.func,
  roomNumber: PropTypes.number
};
